import React, { useEffect, useState } from "react";
import { HiOutlineUser } from "react-icons/hi";
import { TbWallet } from "react-icons/tb";
import Modal from "@mui/material/Modal";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlinePoweroff,
} from "react-icons/ai";
import { FiArrowLeft } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import useAuth from "../hooks/useAuth";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { AiOutlineMenu } from "react-icons/ai";
import { TG_LP_URL } from "../globalVariables";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import SocialMedia from "./SocialMedia";
import { SERVER_URL } from "../globalVariables";

const Header = ({ moreStyle, logo }) => {
  const {
    currentUser,
    moreInfoCurrentUser,
    error,
    result,
    timeActive,
    openConnexionModal,
    openSignInModal,
    openSignUpModal,
    openConfirmEmailModal,
    setError,
    setResult,
    setTimeActive,
    setOpenConnexionModal,
    setOpenSignInModal,
    setOpenSignUpModal,
    setOpenConfirmEmailModal,
    setBuyNowActive,
    LoginWithEmailAndPassword,
    PasswordReset,
    RegisterWithEmailAndPassword,
    ResendEmailVerification,
    UserReload,
    Logout,
    VerifyMetamaskExtension,
    isLoading,
  } = useAuth();
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [openBurgerMenuModal, setOpenBurgerMenuModal] = useState(false);
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [name, setName] = useState({ firstName: "", lastName: "" });
  const [birthDate, setBirthDate] = useState({ month: "", day: "", year: "" });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [time, setTime] = useState(60);
  const [artists, setArtists] = useState();
  const dateYear = new Date().getFullYear();
  const navigate = useNavigate();

  useEffect(() => {
    let interval = null;

    if (timeActive && time !== 0) {
      interval = setInterval(() => {
        setTime((time) => time - 1);
        UserReload();
      }, 1000);
    } else if (time === 0) {
      setTimeActive(false);
      setTime(60);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timeActive, time]);

  const ValueReset = () => {
    setName({ firstName: "", lastName: "" });
    setBirthDate({ month: "", day: "", year: "" });
    setEmail("");
    setPassword("");
    setViewPassword(false);
    setViewConfirmPassword(false);
    setConfirmPassword("");
    setError("");
    setResult("");
    setBuyNowActive(false);
  };

  const handleDayChange = (e) => {
    const limit = 2;

    setBirthDate({
      ...birthDate,
      day:
        e.target.value.slice(0, limit) == 0
          ? ""
          : e.target.value.slice(0, limit) < 32 &&
            Math.sign(e.target.value) !== -1
          ? e.target.value.slice(0, limit)
          : e.target.value.slice(0, 1),
    });
  };

  const handleMonthChange = (e) => {
    const limit = 2;

    setBirthDate({
      ...birthDate,
      month:
        e.target.value.slice(0, limit) == 0
          ? ""
          : e.target.value.slice(0, limit) < 13 &&
            Math.sign(e.target.value) !== -1
          ? e.target.value.slice(0, limit)
          : e.target.value.slice(0, 1),
    });
  };

  const handleYearChange = (e) => {
    const limit = 4;
    let currentYear = new Date().getFullYear();

    setBirthDate({
      ...birthDate,
      year:
        e.target.value.length < 4 && Math.sign(e.target.value) !== -1
          ? e.target.value
          : e.target.value.slice(0, limit) >= currentYear - 100 &&
            e.target.value.slice(0, limit) <= currentYear - 16
          ? e.target.value.slice(0, limit)
          : "",
    });
  };

  const register = () => {
    if (
      email !== "" &&
      password !== "" &&
      confirmPassword !== "" &&
      name?.firstName !== "" &&
      name?.lastName !== ""
    ) {
      if (validator.isEmail(email) === false) {
        setError("Email is invalid.");
      } else if (password !== confirmPassword) {
        setError("Passwords do not match.");
      } else if (validator.isStrongPassword(password) === false) {
        setError(
          "Password must contain at least 8 characters with 1 number, 1 upper case letter and 1 special symbol."
        );
      } else if (
        (birthDate.day !== "" ||
          birthDate.month !== "" ||
          birthDate.year !== "") &&
        (birthDate.day === "" ||
          birthDate.month === "" ||
          birthDate.year === "")
      ) {
        setError("Please complete all birthdate fields");
      } else if (birthDate.year !== "" && birthDate.year.length <= 3) {
        setError("Please enter a valid date (DD-MM-YYYY).");
      } else {
        RegisterWithEmailAndPassword(name, birthDate, email, password);
      }
    } else {
      setError("Please complete all required fields.");
    }
  };

  function handleDropdown() {
    return setDropdownMenu(!dropdownMenu);
  }

  useEffect(() => {
    const getArtists = () => {
      fetch(`${SERVER_URL}/api/getArtists`)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setArtists(data);
          }
        })
        .catch((error) => console.log(error));
    };

    getArtists();
  }, []);

  return (
    <div className={"w-full top-0 z-50 px-6 md:px-8 bg-[#00263a] " + moreStyle}>
      <div className="flex justify-between space-x-2 w-full h-[70px] lg:h-[100px] items-center ">
        <div className="flex space-x-3 lg:space-x-0 items-center">
          <AiOutlineMenu
            className="flex lg:hidden w-8 h-8 md:w-9 md:h-9 text-white cursor-pointer"
            onClick={() => setOpenBurgerMenuModal(true)}
          />

          <div
            className={`transition-opacity duration-700 ${
              logo ? "opacity-100" : "opacity-0 invisible"
            }`}
          >
            <Link to="/">
              <img
                src="/images/logo.png"
                className="w-[120px] sm:w-[140px] md:w-[160px] lg:w-[180px] h-[35px] sm:h-[40px] md:h-[45px] lg:h-[50px]"
                alt=""
              />
            </Link>
          </div>
        </div>

        <ul className="flex items-center space-x-2 md:space-x-3 z-50">
          <li className="relative">
            <button
              className="text-white text-[15px] md:text-base text-end font-medium tracking-wide cursor-pointer"
              onClick={handleDropdown}
            >
              Artists
            </button>

            {dropdownMenu && (
              <div className="absolute top-[40px] right-0 w-[200px] flex flex-col bg-white/90 rounded-xl p-6 space-y-6">
                <div className="flex flex-col space-y-2">
                  <label className="text-lg text-right font-bold">
                    Artists
                  </label>

                  <div className="flex flex-col space-y-1">
                    {artists?.map(
                      (artist) =>
                        artist.status === "active" && (
                          <button
                            key={artist.artistId}
                            className="text-right font-medium"
                            onClick={() => {
                              navigate(`/artist/${artist.artistId}`);
                              handleDropdown();
                            }}
                          >
                            {artist.firstName + " " + artist.lastName}
                          </button>
                        )
                    )}
                  </div>
                </div>

                <div className="flex flex-col space-y-2">
                  <label className="text-lg text-right font-bold">
                    Coming soon
                  </label>

                  <div className="flex flex-col space-y-1">
                    {artists?.map(
                      (artist) =>
                        artist.status === "upcoming" && (
                          <button
                            key={artist.artistId}
                            className="text-right font-medium"
                            onClick={() => {
                              navigate(`/artist/${artist.artistId}`);
                              handleDropdown();
                            }}
                            disabled={true}
                          >
                            {artist.firstName + " " + artist.lastName}
                          </button>
                        )
                    )}
                  </div>
                </div>
              </div>
            )}
          </li>

          {currentUser ? (
            <li
              className="border rounded-full p-2 cursor-pointer"
              onClick={Logout}
            >
              <AiOutlinePoweroff className="w-5 h-5 text-white" />
            </li>
          ) : isLoading ? (
            <li className="flex">
              <CircularProgress
                sx={{ color: "#33c7b6" }}
                style={{ height: 35, width: 35 }}
              />
            </li>
          ) : (
            <li
              className="border rounded-full p-2 cursor-pointer"
              onClick={() => {
                VerifyMetamaskExtension();
              }}
            >
              <TbWallet className="w-5 h-5 text-white" />
            </li>
          )}

          {currentUser ? (
            <li>
              <Link to="/profile">
                <img
                  src={
                    moreInfoCurrentUser?.profilePicture
                      ? moreInfoCurrentUser.profilePicture
                      : "/images/tg-profile.png"
                  }
                  className="w-10 h-10 object-cover rounded-full cursor-pointer"
                  alt=""
                />
              </Link>
            </li>
          ) : (
            <li
              className="border rounded-full p-2 cursor-pointer"
              onClick={() => {
                setBuyNowActive(false);
                setOpenConnexionModal(true);
              }}
            >
              <HiOutlineUser className="w-5 h-5 text-white" />
            </li>
          )}
        </ul>
      </div>

      <Modal
        open={openConnexionModal}
        onClose={() => {
          setOpenConnexionModal(false);
          ValueReset();
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white sm:rounded-xl overflow-auto scrollbar-hide w-full sm:w-[450px] h-full sm:h-[650px] outline-none relative">
          <div className="flex justify-end items-center p-6">
            <RxCross2
              className="w-11 h-11 cursor-pointer"
              onClick={() => {
                setOpenConnexionModal(false);
                ValueReset();
              }}
            />
          </div>

          <div className="flex flex-col space-y-16">
            <div className="flex flex-col space-y-24 items-center">
              <img className="w-40" src="/images/logo-blue.png" alt="" />

              <p className="text-[#00263a] text-[24px] font-bold">Login</p>
            </div>

            <div className="flex flex-col items-center ">
              <div className="flex flex-col space-y-4">
                <button
                  className="bg-[#00263a] font-source px-6 py-2 rounded-full cursor-pointer text-white"
                  onClick={() => {
                    VerifyMetamaskExtension();
                  }}
                >
                  Connect wallet
                </button>

                <button
                  className="px-6 py-1.5 border-2 font-source border-[#00263a] rounded-full"
                  onClick={() => {
                    setOpenConnexionModal(false);
                    setOpenSignInModal(true);
                  }}
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openSignInModal}
        onClose={() => {
          setOpenSignInModal(false);
          ValueReset();
        }}
        className="flex items-center justify-center "
      >
        <div className="flex flex-col bg-white w-full sm:w-[510px] overflow-auto scrollbar-hide h-full sm:h-[770px] relative sm:rounded-xl outline-none">
          <div className="flex justify-between items-center p-6">
            <FiArrowLeft
              className="w-11 h-11 cursor-pointer"
              onClick={() => {
                setOpenSignInModal(false);
                ValueReset();
                setOpenConnexionModal(true);
              }}
            />

            <RxCross2
              className="w-11 h-11 cursor-pointer"
              onClick={() => {
                setOpenSignInModal(false);
                ValueReset();
              }}
            />
          </div>

          <div className="flex flex-col space-y-16">
            <div className="flex flex-col space-y-10 items-center">
              <img className="w-40" src="/images/logo-blue.png" alt="" />

              <p className="text-[#00263a] text-[24px] font-bold">Sign in</p>
            </div>

            <div className="flex flex-col space-y-6 px-8">
              <div className="font-source">
                <label className="block text-[#515151]">Email adress *</label>

                <div className="flex mt-0.5 p-3 border border-gray-400 rounded-lg w-full">
                  <input
                    type="email"
                    className="outline-none w-full font-bold"
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="font-source">
                <label className="block text-[#515151]">Password *</label>

                <div className="flex mt-0.5 p-3 border space-x-2 border-gray-400 rounded-lg w-full">
                  <input
                    type={viewPassword ? "text" : "password"}
                    className="outline-none w-full font-bold"
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                  />

                  {viewPassword ? (
                    <AiOutlineEyeInvisible
                      className="w-6 h-6 cursor-pointer text-[#979797]"
                      onClick={() => setViewPassword(false)}
                    />
                  ) : (
                    <AiOutlineEye
                      className="w-6 h-6 cursor-pointer text-[#979797]"
                      onClick={() => setViewPassword(true)}
                    />
                  )}
                </div>

                <button
                  className="block text-xs font-source pt-2 font-medium underline cursor-pointer"
                  onClick={() => {
                    ValueReset();
                    setOpenSignInModal(false);
                    setOpenResetPasswordModal(true);
                  }}
                >
                  Forgot your password
                </button>

                {error && (
                  <div className="">
                    <span className="block text-sm pt-2 font-medium text-[#FD866C]">
                      {error}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col items-center w-full space-y-2 font-source pb-8">
              <button
                className="flex px-16 py-2 bg-[#00263a] font-source text-white rounded-full justify-center"
                onClick={() => LoginWithEmailAndPassword(email, password)}
              >
                Login
              </button>

              <div className="flex justify-center">
                <p className="text-sm font-source text-[#00263A]">
                  Don't have an account ?{" "}
                  <span
                    className="underline font-source cursor-pointer"
                    onClick={() => {
                      ValueReset();
                      setOpenSignInModal(false);
                      setOpenSignUpModal(true);
                    }}
                  >
                    Sign up
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openResetPasswordModal}
        onClose={() => {
          setOpenResetPasswordModal(false);
          ValueReset();
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white w-full sm:w-[550px] overflow-auto scrollbar-hide h-full sm:h-[770px] relative sm:rounded-xl outline-none">
          <div className="flex justify-between items-center p-6">
            <FiArrowLeft
              className="w-11 h-11 cursor-pointer"
              onClick={() => {
                ValueReset();
                setOpenResetPasswordModal(false);
                setOpenSignInModal(true);
              }}
            />

            <RxCross2
              className="w-11 h-11 cursor-pointer"
              onClick={() => {
                ValueReset();
                setOpenResetPasswordModal(false);
              }}
            />
          </div>

          <div className="flex flex-col space-y-16">
            <div className="flex flex-col space-y-24 items-center">
              <img className="w-40" src="/images/logo-blue.png" alt="" />

              <p className="text-[#00263a] text-[24px] font-bold">
                Forgot password
              </p>
            </div>

            <div className="flex flex-col space-y-6 px-8 font-source">
              <p className="block pt-2 text-[#00263a] text-center">
                Enter your email address to receive a password reset link
              </p>

              <div className="">
                <label className="block text-[#515151]">Email adress *</label>

                <div className="flex mt-0.5 p-3 border border-gray-400 rounded-lg w-full">
                  <input
                    type="email"
                    className="outline-none w-full font-bold"
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                </div>
              </div>

              {result && (
                <div className="">
                  <span className="block text-sm pt-2 font-medium">
                    {result}
                  </span>
                </div>
              )}
            </div>

            <div className="flex justify-center pb-8">
              <button
                className="flex px-8 py-2 font-source bg-[#00263a] text-white rounded-full justify-center"
                onClick={() => PasswordReset(email)}
              >
                Send link
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openSignUpModal}
        onClose={() => {
          setOpenSignUpModal(false);
          ValueReset();
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white overflow-auto scrollbar-hide sm:rounded-xl w-full sm:w-[550px] h-full sm:h-[850px] outline-none">
          <div className="flex justify-between items-center p-6 ">
            <FiArrowLeft
              className="w-11 h-11 cursor-pointer"
              onClick={() => {
                ValueReset();
                setOpenSignUpModal(false);
                setOpenSignInModal(true);
              }}
            />

            <RxCross2
              className="w-11 h-11 cursor-pointer"
              onClick={() => {
                ValueReset();
                setOpenSignUpModal(false);
              }}
            />
          </div>

          <div className="flex flex-col space-y-10">
            <div className="flex flex-col space-y-8 items-center">
              <img className="w-40" src="/images/logo-blue.png" alt="" />

              <p className="text-[#00263a] text-[24px] font-bold">Sign up</p>
            </div>

            <div className="flex flex-col space-y-4 px-8">
              <div className="flex space-x-8">
                <div className="font-source">
                  <label className="block text-[#515151]">First name *</label>

                  <div className="flex mt-0.5 p-2 border border-gray-400 rounded-lg w-full">
                    <input
                      type="text"
                      className="w-full outline-none font-bold"
                      onChange={(event) => {
                        setName({
                          ...name,
                          firstName: event.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="font-source">
                  <label className="block text-[#515151]">Last name *</label>

                  <div className="flex mt-0.5 p-2 border border-gray-400 rounded-lg w-full">
                    <input
                      type="text"
                      className="w-full outline-none font-bold"
                      onChange={(event) => {
                        setName({
                          ...name,
                          lastName: event.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex space-x-8 font-source">
                <div className="flex items-center mt-6">
                  <span className="block text-[#515151]">Birthdate</span>
                </div>

                <div className="flex space-x-3">
                  <div className="w-[35%]">
                    <label className="block text-[#515151]">Day</label>

                    <div className="flex mt-0.5 p-2 border border-gray-400 rounded-lg w-full">
                      <input
                        type="number"
                        placeholder="DD"
                        className="w-full outline-none font-bold"
                        value={birthDate.day}
                        onChange={handleDayChange}
                      />
                    </div>
                  </div>

                  <div className="w-[35%]">
                    <label className="block text-[#515151]">Month</label>

                    <div className="flex mt-0.5 p-2 border border-gray-400 rounded-lg w-full">
                      <input
                        type="number"
                        placeholder="MM"
                        className="w-full outline-none font-bold"
                        value={birthDate.month}
                        onChange={handleMonthChange}
                      />
                    </div>
                  </div>

                  <div className="w-[55%]">
                    <label className="block text-[#515151]">Year</label>

                    <div className="flex mt-0.5 p-2 border border-gray-400 rounded-lg w-full">
                      <input
                        type="number"
                        placeholder="YYYY"
                        className="w-full outline-none font-bold"
                        value={birthDate.year}
                        onChange={handleYearChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="font-source">
                <label className="block text-[#515151]">Email adress *</label>

                <div className="flex mt-0.5 p-2 border border-gray-400 rounded-lg w-full">
                  <input
                    type="email"
                    className="w-full outline-none font-bold"
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="font-source">
                <label className="block text-[#515151]">Password *</label>

                <div className="flex mt-0.5 p-2 border space-x-2 border-gray-400 rounded-lg w-full">
                  <input
                    type={viewPassword ? "text" : "password"}
                    className="outline-none w-full font-bold"
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                  />

                  {viewPassword ? (
                    <AiOutlineEyeInvisible
                      className="w-6 h-6 cursor-pointer text-[#979797]"
                      onClick={() => setViewPassword(false)}
                    />
                  ) : (
                    <AiOutlineEye
                      className="w-6 h-6 cursor-pointer text-[#979797]"
                      onClick={() => setViewPassword(true)}
                    />
                  )}
                </div>
              </div>

              <div className="font-source">
                <label className="block text-[#515151]">
                  Confirm password *
                </label>

                <div className="flex mt-1 p-2 border space-x-2 border-gray-400 rounded-lg w-full">
                  <input
                    type={viewConfirmPassword ? "text" : "password"}
                    className="outline-none w-full font-bold"
                    onChange={(event) => {
                      setConfirmPassword(event.target.value);
                    }}
                  />

                  {viewConfirmPassword ? (
                    <AiOutlineEyeInvisible
                      className="w-6 h-6 cursor-pointer text-[#979797]"
                      onClick={() => setViewConfirmPassword(false)}
                    />
                  ) : (
                    <AiOutlineEye
                      className="w-6 h-6 cursor-pointer text-[#979797]"
                      onClick={() => setViewConfirmPassword(true)}
                    />
                  )}
                </div>

                <div className="">
                  <p className="text-[11px] text-[#7C7C7C]">
                    Password must be at least 8 characters with 1 number, 1
                    upper case letter and 1 special symbol
                  </p>
                </div>

                {error && (
                  <div className="">
                    <span className="block text-sm pt-2 font-medium text-[#FD866C]">
                      {error}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col items-center w-full space-y-2 font-source pb-8">
              <button
                className="flex px-16 py-2 bg-[#00263a] text-white rounded-full justify-center"
                onClick={register}
              >
                Sign up
              </button>

              <p className="text-sm text-center text-[#00263A]">
                Already have an account ?{" "}
                <span
                  className="underline cursor-pointer"
                  onClick={() => {
                    ValueReset();
                    setOpenSignUpModal(false);
                    setOpenSignInModal(true);
                  }}
                >
                  Sign in
                </span>
              </p>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openConfirmEmailModal}
        onClose={() => {
          setOpenConfirmEmailModal(false);
          setTimeActive(false);
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white sm:rounded-xl w-full sm:w-[510px] overflow-auto scrollbar-hide h-full sm:h-[770px] outline-none relative">
          <div className="flex justify-end items-center p-6">
            <RxCross2
              className="w-11 h-11 cursor-pointer"
              onClick={() => {
                setOpenConfirmEmailModal(false);
                setTimeActive(false);
              }}
            />
          </div>

          <div className="flex flex-col space-y-16">
            <div className="flex flex-col space-y-24 items-center">
              <img className="w-40" src="/images/logo-blue.png" alt="" />

              <p className="text-[#00263a] text-[24px] font-bold">
                Confirm your email
              </p>
            </div>

            <div className="flex flex-col items-center font-source pb-8">
              <p className="">
                We sent an email to{" "}
                <span className="font-black">{currentUser?.email}</span>
              </p>
              <p className="">Please confirm your account at this address.</p>

              <div className="flex space-x-1 pt-10 items-center text-[#00263a]">
                <p className="text-xs">I didn't receive an email.</p>

                <button
                  className="text-xs  bg-gray-100 py-1 px-2 rounded-lg"
                  onClick={ResendEmailVerification}
                  disabled={timeActive}
                >
                  Send an other {timeActive && "(" + time + ")"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openBurgerMenuModal}
        onClose={() => {
          setOpenBurgerMenuModal(false);
        }}
        className="flex lg:hidden"
      >
        <div className="flex flex-col bg-[#00263a] w-full md:w-[300px] h-full outline-none">
          <div className="flex justify-start md:justify-end items-center p-4">
            <RxCross2
              className="w-11 h-11 cursor-pointer text-white"
              onClick={() => {
                setOpenBurgerMenuModal(false);
              }}
            />
          </div>

          <div className="flex flex-col py-10 px-6 space-y-10 w-full text-white">
            <div className="w-full items-center">
              <img className="w-40" src="/images/logo.png" alt="" />
            </div>

            <a
              className="text-lg text-[15px] sm:text-[16px]"
              href={`${TG_LP_URL}`}
              target="_blank"
              rel="noreferrer"
            >
              About us
            </a>

            <a
              className="text-lg text-[15px] sm:text-[16px]"
              href={`${TG_LP_URL}/terms-and-conditions`}
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </a>

            <a
              className="text-lg text-[15px] sm:text-[16px]"
              href={`${TG_LP_URL}/faq`}
              target="_blank"
              rel="noreferrer"
            >
              FAQ
            </a>

            <p className="text-lg text-[15px] sm:text-[16px]">
              © {dateYear} - The Gallery • V1.0.1
            </p>

            <SocialMedia />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Header;
