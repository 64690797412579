import React from "react";
import { TG_LP_URL } from "../globalVariables";
import SocialMedia from "./SocialMedia";

const StickBar = ({ moreStyle }) => {
  const dateYear = new Date().getFullYear();

  return (
    <div
      className={
        "absolute w-[110px] py-4 space-y-[150px] hidden lg:inline " + moreStyle
      }
    >
      <div
        className="flex items-center text-white space-y-10 w-full "
        style={{ writingMode: "vertical-rl" }}
      >
        <a
          className="rotate-180 text-lg text-[15px] sm:text-[16px] flex flex-none"
          href={`${TG_LP_URL}`}
          target="_blank"
          rel="noreferrer"
        >
          About us
        </a>

        <a
          className="rotate-180 text-lg text-[15px] sm:text-[16px] flex flex-none"
          href={`${TG_LP_URL}/terms-and-conditions`}
          target="_blank"
          rel="noreferrer"
        >
          Terms & Conditions
        </a>

        <a
          className="rotate-180 text-lg text-[15px] sm:text-[16px] flex flex-none"
          href={`${TG_LP_URL}/faq`}
          target="_blank"
          rel="noreferrer"
        >
          FAQ
        </a>

        <p className="rotate-180 text-lg text-[15px] sm:text-[16px] flex flex-none">
          <span className="rotate-90">©</span> {dateYear} - The Gallery • V1.0.1
        </p>
      </div>

      <SocialMedia />
    </div>
  );
};

export default StickBar;
